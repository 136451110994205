html {
    background: #444;
}

body {
    background: #444;
    padding-bottom: 80px;
    font-family: 'Open Sans', sans-serif;
}

a {
    cursor: pointer;
}

h1, h2, h3, h4, h5, h6 {
    line-height: 1.1;
}

.header a:hover {
    color: #FFF !important;
}

.col-md-3 {
    padding-left: 5px;
    padding-right: 5px;
}

.search-control-0 .rbt-input-main {
    border-radius: 0;
}

.station-parameters {
    flex: 1;
    margin: 10px 10px 10px 0;
}

.station-parameters input {
    border: none;
    height: 34px;
    background-color: #191919;
    color: #AAA;
}

.station-parameters input:focus {
    background-color: #191919;
    color: #AAA;
    outline: none;
    border: none;
    box-shadow: none;
}

.station-parameters input::placeholder {
    color: #505050;
}

.station-parameters .dropdown-menu {
    background: #191919;
}

.station-parameters .dropdown-menu .dropdown-item {
    color: #AAA;
}

.secondary-artwork {
    display: none;
}

.small-screen-label {
    display: none;
}

@media (max-width: 410px) {
    .album-art {
        height: auto !important;
        padding-top: 100%;
    }
    .lastfm-connect-link {
        padding: 10px 5px !important;
    }
    .small-screen-label {
        display: inline;
    }
    .large-screen-label {
        display: none;
    }
}

@media (min-width: 640px) {
    .station-list {
        grid-template-columns: 1fr 1fr;
    }
}

.playlist .album-header .dropdown-menu svg,
.playlist .album-header:hover .dropdown-menu svg,
.playlist .playlist-item .dropdown-menu svg,
.playlist .playlist-item:hover .dropdown-menu svg {
    opacity: 0.35;
}

.playlist .album-header svg,
.playlist .playlist-item svg {
    opacity: 0.1;
    transition: opacity 0.3s;
}

.playlist .album-header:hover svg,
.playlist .playlist-item:hover svg {
    opacity: 0.8;
}

.playlist .playlist-item {
    transition: background-color 0.3s;
}

.playlist .playlist-item.current {
    background: #E0E0E0;
    color: #000;
}

.playlist .playlist-item:not(.current):hover {
    background: #2A2A2A;
}

.dropdown-menu .dropdown-item {
    padding: 0.25rem 1rem;
}

.dropdown-menu .dropdown-item:hover {
    background: #EEE;
}

.btn {
    border-radius: 0;
}

.btn-black {
    border: none;
    background: #000;
    color: #c8c8c8;
}

.btn-black:hover {
    color: #FFF;
}

.alert {
    margin: 0;
    border-radius: 0;
    border: none;
}

.alert-warning {
    color: #352900;
    background-color: #ffc123;
}

.rbt-input-hint {
    color: #FFFFFF77 !important;
}

.header .title-container {
    margin: 5px 0 5px;
}

.header h2 {
    font-size: 36px;
}

.header .logo {
    height: 40px;
    width: 40px;
}

.header .lastfm-profile {
    font-size: 12px;
}

.header .lastfm-profile img {
    height: 35px;
    width: 35px;
}

@media (max-width: 480px) {
    .header .title-container {
        margin: 2px 0 1px;
    }

    .header h2 {
        font-size: 30px;
    }

    .header .logo {
        height: 34px;
        width: 34px;
    }

    .header .lastfm-profile {
        font-size: 10px;
    }

    .header .lastfm-profile img {
        height: 25px;
        width: 25px;
    }
}